import type { AllowedComponentProps, Component, VNodeProps } from 'vue'

// Add components here to be able to use them in .html.erb files using the VueComponent
export const RegisteredComponents = {
  Chatbot: () => import('../chatbot/components/Chatbot.vue'),
  DocuSignSendFolder: () => import('../docu_sign_send_folder/DocuSignSendFolder.vue'),
  TemplateAutoName: () => import('../../views/templates/components/Advanced/Advanced.vue'),
  TemplateWebhookTriggers: () => import('../../views/templates/components/Advanced/AllowedTriggers.vue'),
  TemplateMetadata: () => import('../../views/templates/components/Advanced/Metadata.vue'),
  TemplateAttributes: () => import('../../views/templates/components/Attributes/App.vue'),
  TemplateStyles: () => import('../../views/templates/components/Styles/StylesLoader.vue'),
  TemplateNotes: () => import('../../views/templates/components/Notes/App.vue'),
  TemplateCharactersWhitelist: () => import('../../views/templates/components/Advanced/CharactersWhitelist.vue'),
  TemplateCharacterLimitInput: () => import('../../views/templates/components/Advanced/CharacterLimitInput.vue'),
  TemplateValidationControllers: () => import ('../../views/templates/components/Advanced/ValidationControllers.vue'),
  TemplateSummary: () => import('../../views/templates/components/Summary/App.vue'),
  AutoNameInput: () => import('../auto_name_input/AutoNameInput.vue'),
  CustomReports: () => import('../custom_reports/CustomReports.vue'),
  DealEdit: () => import('../deals/DealEdit.vue'),
  DealIndex: () => import('../deals/DealIndex.vue'),
  DealFormResponse: () => import('../deals/DealFormResponse.vue'),
  DealDashboard: () => import('../deals/DealDashboard.vue'),
  ArchiveDialog: () => import('../archive_dialog/ArchiveDialog.vue'),
  MoveToFolderDialog: () => import('../move_to_folder_dialog/MoveToFolderDialog.vue'),
  EmailSmartSubject: () => import('../email_templates/SmartSubject.vue'),
  PropertiesSidebar: () => import('../../features/properties_sidebar/PropertiesSidebar.vue'),
  FileUploadDialog: () => import('../../features/drive/file_upload_dialog/FileUploadDialog.vue'),
  ToggleUserModal: () => import('../../features/users/ToggleUserModal.vue'),
  TemplatePacksDashboard: () => import('../../features/template_packs/Dashboard.vue'),
  TemplatePack: () => import('../../features/template_packs/TemplatePack.vue'),
  UserSettings: () => import('../../features/user_settings/Dashboard.vue'),
} as const

type ComponentProps<C extends Component> = C extends new (...args: any) => any ? Omit<InstanceType<C>['$props'], keyof VNodeProps | keyof AllowedComponentProps> : never

export type RegisteredComponentsProps = {
  [T in keyof typeof RegisteredComponents]: ComponentProps<Awaited<ReturnType<(typeof RegisteredComponents)[T]>>['default']>
}
